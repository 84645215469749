<template>
  <div class="lad-countdown with-countdown">
    <el-container>
      <el-main>
        <div class="lad-countdown--container">
          <div class="lad-countdown--header">
            <div class="lad-countdown--logo">
              <img
                :src="banner.url"
                :title="banner.title"
                :alt="banner.title"
                width="135px"
                height="auto"
              />
            </div>

            <div class="lad-countdown--header-text">
              <p class="h2">{{ banner.title }}</p>
              <span>{{ banner.subtitle }}</span>
            </div>
          </div>

          <div class="lad-countdown--right">
            <span v-if="counter" class="counter-phocuswright">{{
              `${counter} ${banner.counter.spots}`
            }}</span>
            <span v-else class="counter-phocuswright">{{
              banner.counter.waiting
            }}</span>

            <div class="lad-countdown--btn">
              <el-link
                v-scroll-to="{ el: '#lad-landing--form', offset: 25 }"
                :underline="false"
                class="el-button el-button--success"
              >
                <span>{{ banner.cta }}</span>
              </el-link>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import experience from '@/db/phocuswright/experience'

export default {
  name: 'LadPhocuswrightCountDown',
  props: {
    counter: { type: Number, default: 0 },
  },
  computed: {
    banner() {
      return experience.banner
    },
  },
}
</script>

<style lang="scss">
.lad-countdown {
  padding: 0 20px;
  background-color: white;
  // color: white;

  &--container,
  &--right {
    // color: white;
    display: grid;
    grid-gap: 20px;
    align-items: start;
    grid-template-columns: 1fr auto;
  }

  &--right {
    height: 100%;
  }

  &.without-countdown {
    .lad-countdown--right {
      grid-gap: 0;
    }
  }

  .counter-phocuswright {
    font-size: 30px;
    font-weight: bold;
    align-self: center;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  &--logo {
    text-align: center;
    max-width: 135px;
    margin-right: 15px;

    img {
      width: 100%;
    }

    .sprite-icons {
      width: 95px;
      height: 95px;
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-text {
      width: 100%;
    }

    .h2 {
      line-height: 1.2em;
      margin: 5px 0 10px;
      font-weight: bold;
      font-size: 1.7em;
    }

    span {
      display: block;
      font-size: 1.2em;
      margin-bottom: 5px;
    }
  }

  &--btn {
    align-self: center;

    .el-link {
      padding: 15px 25px;
      color: #fff;
      border-radius: 0;
      // background-color: #525252;
      // border-color: #525252;
    }
  }

  /* vue2-flip-countdown styles */
  .flip-clock-container {
    justify-self: center;
    align-self: center;
    height: 70px;
    width: 310px;
  }

  .flip-clock {
    align-self: center;
    text-align: right !important;
    height: 70px;
  }
  .flip-clock__piece {
    text-align: center !important;
  }
  .flip-card {
    font-size: 2rem !important;
  }
  .flip-clock__slot {
    font-size: 0.8rem !important;
  }

  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    color: white !important;
  }

  @media screen and (max-width: 992px) {
    .flip-clock-container {
      width: 285px;
    }
  }

  @media screen and (max-width: 768px) {
    &--container {
      grid-gap: 15px;
      grid-template-columns: 1fr;
    }

    &--logo {
      max-width: 135px;

      .sprite-icons {
        width: 90px;
        height: 90px;
      }
    }

    &--header {
      text-align: center;
    }

    &--btn {
      text-align: center;

      .el-link {
        padding: 10px 25px;
      }
    }

    .flip-card {
      width: 1.6em !important;
    }

    .flip-clock-container {
      width: unset;
    }
  }

  @media screen and (max-width: 465px) {
    .flip-card {
      font-size: 1.6rem !important;
    }

    .flip-clock-container,
    .flip-clock {
      height: 60px;
    }
  }

  @media screen and (max-width: 400px) {
    &--header {
      .h2 {
        font-size: 1.4em;
      }

      span {
        font-size: 1.1em;
      }
    }

    .flip-card {
      font-size: 1.5rem !important;
    }

    .flip-clock-container,
    .flip-clock {
      height: 55px;
    }
  }

  @media screen and (max-width: 370px) {
    &--right {
      display: block;
      justify-self: center;
    }

    .flip-card__top,
    .flip-card__bottom,
    .flip-card__back-bottom,
    .flip-card__back::before,
    .flip-card__back::after {
      width: 1.5em !important;
      padding: 0.25em 0.1em 0.4em !important;
    }
  }

  @media screen and (max-width: 345px) {
    .flip-card__bottom,
    .flip-card__back-bottom,
    .flip-card__bottom-4digits,
    .flip-card__back-bottom-4digits {
      top: 44% !important;
    }
  }
}
</style>
