<template>
  <div
    class="lad-countdown"
    :class="[builder.show_count_down ? 'with-countdown' : 'without-countdown']"
    :style="bannerStyle"
  >
    <el-container
      v-if="isReservation"
      class="lad-countdown--container-principal lad-countdown--reservation"
    >
      <el-main class="lad-countdown--main-principal">
        <div class="lad-countdown--container">
          <div class="lad-countdown--header">
            <div class="lad-countdown--header-text">
              <div class="icon-with-title">
                <i class="fas fa-dollar-sign"></i>
                <p class="h2">{{ builder.name }}</p>
              </div>
            </div>

            <div class="lad-countdown--right">
              <div v-if="builder.show_count_down" class="flip-clock-container">
                <client-only>
                  <FlipCountdown :deadline="deadline" :labels="labels" />
                </client-only>
              </div>

              <div v-if="link" class="lad-countdown--btn">
                <el-link
                  v-if="link.startsWith('#')"
                  v-scroll-to="{ el: link, offset: 25 }"
                  :underline="false"
                  class="el-button el-button--info"
                  :style="ctaStyle"
                >
                  <span>{{ btnText }}</span>
                </el-link>

                <nuxt-link
                  v-else
                  :to="link"
                  :class="btnClass"
                  :style="ctaStyle"
                  >{{ btnText }}</nuxt-link
                >
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-container v-else class="lad-countdown--container-principal">
      <el-main class="lad-countdown--main-principal">
        <div class="lad-countdown--container">
          <div class="lad-countdown--header">
            <div v-if="hasLogo" class="lad-countdown--logo">
              <img
                :src="builder.logo.url"
                :title="builder.name"
                :alt="builder.name"
                width="40px"
                height="40px"
              />
            </div>

            <div class="lad-countdown--header-text">
              <p
                class="h2"
                :style="{ color: builder.styles.banner.color }"
                v-html="builder.name"
              ></p>
              <span>{{ builder.description }}</span>
              <span class="terms-conditions"
                ><nuxt-link to="/terms-and-conditions">{{
                  $t(`customer_benefits.section.terms_and_conditions`)
                }}</nuxt-link></span
              >
            </div>

            <div class="lad-countdown--right">
              <div v-if="builder.show_count_down" class="flip-clock-container">
                <client-only>
                  <FlipCountdown :deadline="deadline" :labels="labels" />
                </client-only>
              </div>

              <div v-if="link" class="lad-countdown--btn">
                <el-link
                  v-if="link.startsWith('#')"
                  v-scroll-to="{ el: link, offset: 25 }"
                  :underline="false"
                  class="el-button el-button--info"
                  :style="ctaStyle"
                >
                  <span>{{ btnText }}</span>
                </el-link>

                <nuxt-link
                  v-else
                  :to="link"
                  :class="btnClass"
                  :style="ctaStyle"
                  >{{ btnText }}</nuxt-link
                >
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LadCountDown',
  components: {
    FlipCountdown: () =>
      import(
        /* webpackChunkName: "vue2-flip-countdown" */ 'vue2-flip-countdown'
      ),
  },
  props: {
    builder: { type: Object, required: true },
    cta: { type: Array, default: () => [] },
    routeName: { type: String, default: '' },
  },
  data() {
    return {
      labels: {
        days: this.$t('components.countdown.days'),
        hours: this.$t('components.countdown.hours'),
        minutes: this.$t('components.countdown.minutes'),
        seconds: this.$t('components.countdown.seconds'),
      },
    }
  },
  computed: {
    ...mapGetters('device', ['isMobile']),
    deadline() {
      return this.builder.end ? this.builder.end : 0
    },
    link() {
      return this.cta.find((t) => t.page.includes(this.routeName)).link
    },
    btnText() {
      return this.cta.find((t) => t.page.includes(this.routeName)).text
    },
    hasLogo() {
      return !!this.builder.logo
    },
    ctaStyle() {
      return {
        backgroundColor: this.builder.styles.cta.background,
        borderColor: this.builder.styles.cta.background,
        color: this.builder.styles.cta.color,
      }
    },
    bannerStyle() {
      return {
        backgroundColor: this.builder.styles.banner.background,
        color: this.builder.styles.banner.color,
      }
    },
    btnClass() {
      return 'el-button el-button--info el-link el-link--default'
    },
    isReservation() {
      return [
        'public-reservation-code-tab',
        'user-reservation-code-tab',
      ].includes(this.routeName)
    },
  },
}
</script>

<style lang="scss">
.lad-countdown {
  padding: 0 15px;
  // background-color: black;
  // color: white;

  &--container-principal {
    margin: 0;
  }

  &--main-principal {
    padding: 10px 0;
  }

  &--container,
  &--right {
    // color: white;
    display: grid;
    align-items: start;
    grid-template-columns: 1fr;
  }

  &--right {
    grid-gap: 10px;
    height: 100%;
  }

  &--logo {
    text-align: center;
    max-width: 40px;

    img {
      width: 100%;
    }

    .sprite-icons {
      width: 40px;
      height: 40px;
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &-text {
      width: 100%;
    }

    .h2 {
      line-height: 1.2em;
      margin: 0 0 5px 0;
      font-weight: bold;
      font-size: 1em;
    }

    span {
      display: block;
      font-size: 0.8em;

      &.terms-conditions {
        display: none;
        margin-top: 5px;
        font-size: 0.8em;
        margin-bottom: 0;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }
  }

  &--btn {
    align-self: center;
    text-align: center;

    .el-link {
      padding: 5px 5px;
      color: #fff;
      border-radius: 2px;
      font-weight: normal;
      background-color: #525252;
      border-color: #525252;
    }
  }

  .flip-clock-container {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 30px;

    &--container-principal {
      margin: auto;
      max-width: unset;
    }

    &--main-principal {
      padding: 15px 0;
    }

    &--container,
    &--right {
      grid-template-columns: 1fr auto;
    }

    &--logo {
      max-width: 95px;
      margin-right: 15px;

      .sprite-icons {
        width: 95px;
        height: 95px;
      }
    }

    &--header {
      .h2 {
        font-size: 1.4em;
      }

      span {
        font-size: 1em;
      }
    }

    &--btn {
      .el-link {
        padding: 10px 15px;
      }
    }

    /* vue2-flip-countdown styles */
    .flip-clock-container {
      justify-self: center;
      align-self: center;
      height: 60px;
      width: 260px;
    }

    .flip-clock {
      align-self: center;
      text-align: right !important;
      height: 60px;
    }
    .flip-clock__piece {
      text-align: center !important;
    }
    .flip-card {
      font-size: 1.6rem !important;
    }
    .flip-clock__slot {
      font-size: 0.8rem !important;
    }

    .flip-card__top,
    .flip-card__bottom,
    .flip-card__back-bottom,
    .flip-card__back::before,
    .flip-card__back::after {
      color: white !important;
    }
  }

  // Nuevo diseño para reservaciones
  &--reservation {
    .lad-countdown--header {
      justify-content: center;

      &-text {
        width: unset;
        max-width: 50%;
        color: #432884;

        .icon-with-title {
          display: flex;
          gap: 5px;
          color: #432884;
          align-items: center;

          i {
            color: white;
            background-color: #0a960c;
            width: 20px;
            height: 20px;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            border-radius: 7px;
            min-width: 20px;
            min-height: 20px;
          }

          p {
            line-height: 20px;
            color: #432884;
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .lad-countdown--right {
      .flip-clock-container {
        color: #432884;
        display: block;

        .flip-card__top,
        .flip-card__bottom,
        .flip-card__back-bottom,
        .flip-card__back::before,
        .flip-card__back::after {
          color: white;
          background: #432884;
        }

        .flip-card__bottom,
        .flip-card__back-bottom {
          border: 1px solid rgba(0, 0, 0, 0.251);
        }

        .flip-card {
          font-size: 1rem;
        }

        .flip-clock__slot {
          font-size: 0.75rem;

          @media screen and (min-width: 768px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
