const db = {
  banner: {
    url:
      'https://cdn.localadventures.io/web-client/phocuswright/the-phocuswright-conference-2022.webp',
    title: 'Reserve your free spot',
    subtitle: "For registered Phocuswright's conference attendees only",
    counter: { spots: 'spots left', waiting: 'Waitlist only' },
    cta: 'Book now',
  },
  video:
    'https://cdn.localadventures.io/web-client/phocuswright/local-adventures-phocuswright-principal-video.mp4',
  principal: Array.from({ length: 9 }, (_, i) => ({
    url: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-phocuswright-principal-${
      i + 1
    }.jpg`,
    srcset: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-phocuswright-principal-${
      i + 1
    }.jpg`,
  })),
  images: Array.from({ length: 18 }, (_, i) => ({
    url: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-phocuswright-${
      i + 1
    }.jpg`,
    srcset: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-phocuswright-${
      i + 1
    }.jpg`,
  })),
  whats_included: [
    {
      uuid: '41292baf-e01a-4b15-83b0-84f50dc15edb',
      title: 'Shared 45-60 minute hot air balloon flight.',
      description: '',
      included: true,
    },
    {
      uuid: 'd3a5a967-47d4-4391-bd90-88d692bf8fbc',
      title: 'Post-flight champagne toast ',
      description: '',
      included: true,
    },
    {
      uuid: '7f8d701e-c8f4-40db-95f1-6affefc4016a',
      title: "Balloonist's ceremony",
      description: '',
      included: true,
    },
    {
      uuid: '46ce7c1f-81b7-4445-b42d-7a3dabf70cfc',
      title: 'A catered breakfast from a recognized local restaurant',
      description: '',
      included: true,
    },
    {
      uuid: 'e6ca313e-3366-4945-ae8f-520a9fa76caf',
      title: 'Commemorative flight certificates',
      description: '',
      included: true,
    },
    {
      uuid: 'cd759237-37cd-48b0-9ef1-de16806a846c',
      title:
        'Amazing opportunity to keep networking where "the sky is the limit".',
      description: '',
      included: true,
    },
  ],
  details: {
    date: 'Nov. 17th, 2022',
    time: 'From 5:45 AM to 11 AM.',
    pickup: 'Pick up at JW Marriott Desert Ridge.',
    description: `
      <p class="my-0 text-center"><strong>“Meet us in the JW Marriott Desert Ridge lobby”</strong></p>
      <p class="my-0">Your experience will start at JW Marriott Desert Ridge at 5:45 AM.</p>
      <p class="mt-0">Please meet us in the JW Marriott Desert Ridge lobby at 5:45AM, as the transportation to the ‘take off zone’ leaves promptly at 6:00AM sharp (pending weather).</p>

      <p class="my-0 text-center""><strong>“The preparation”</strong></p>
      <p class="my-0">When you arrive at the take off zone the process begins with the inflation of the balloon by motorized fans. Once the envelope is filled with air, the propane burners are ignited to heat the air inside. In about thirty minutes the balloon is ready for flight.</p>
      <p class="mt-0">Upon inflation, you will join your pilot in the beautiful hand-crafted wicker basket. The luxurious, oversized basket has plenty of room to allow everyone to enjoy the Arizona flight to the fullest.</p>

      <p class="my-0 text-center""><strong>“The flight”</strong></p>
      <p class="my-0">As you gently leave the Earth, you’ll be amazed at how light the balloon feels. During the flight you have unobstructed 360-degree views, allowing for breathtaking photographs. Your pilot will explore a variety of altitudes, up to 3,000 feet in the air for a marvelous overview of the valley, to just under 400 feet from the ground where you are able to see the sights and sounds of desert life.</p>
      <p class="my-0">Your pilot will point out exotic desert plant life below and descend within inches of trees and cacti, much to everyone’s delight.</p>
      <p class="mt-0">You will be amazed at the excitement and curiosity generated by the passage of our balloons. Occasionally, cars will follow us over the narrow dirt roads in the desert to rendezvous with us after landing.</p>

      <p class="my-0 text-center""><strong>“The chase”</strong></p>
      <p class="mt-0">Throughout your Phoenix hot air balloon ride, the crew will follow our balloon over desert lanes and roads so that they can be ready and waiting for you upon landing, always a momentous occasion.</p>

      <p class="my-0 text-center""><strong>“Get together and enjoy”</strong></p>
      <p class="mt-0">Each balloon might land in a different place, because the course each pilot and air conditions take them, After landing you will be transferred to the final stage where you will be able to delight in the traditional celebration that includes scrumptiously prepared gourmet cuisine. First-time flyers will be treated to the legendary Balloonists Ceremony that balloonists all over the world have been sharing for more than a century. And finally, to prove your difference from ordinary mortals, you will be inducted into the ballooning society and awarded the Certificate d’Ascension En Machine Aerostatique, a truly unique experience.</p>

      <p class="my-0 text-center"><strong>Way back to Hotel.</strong></p>
      <p class="mt-0">After this experience you will be on the way back to JW Marriott Desert Ridge to arrive around 11:00 AM.</p>
    `,
  },
  itinerary: [
    {
      id: 14045,
      timestamp: 'Pick up',
      times: [
        {
          id: 19501,
          time: '5:45 AM',
          title: 'JW Marriot Desert Ridge main lobby',
          location: null,
          description:
            'Please keep in mind that one day before we will have the "happy hour" and because this event is limited in capacity, in any case of absencense you will be leaving someone else without the option, because of this we kindly request your committment to be ready on time.',
        },
      ],
      images: [
        {
          uuid: 'f410a8d6-8624-4490-8dfc-22bb3e36402f',
          url: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-1.jpg`,
        },
      ],
      location: null,
    },
    {
      id: 14046,
      timestamp: 'Drop off',
      times: [
        {
          id: 19502,
          time: '11 AM',
          title: 'JW Marriot Desert Ridge main lobby',
          location: null,
          description:
            'After this experience you will be on the way back to JW Marriott Desert Ridge to arrive around 11:00 AM',
        },
      ],
      images: [
        {
          uuid: 'f410a8d6-8624-4490-8dfc-22bb3e36402f',
          url: `https://cdn.localadventures.io/web-client/phocuswright/local-adventures-6.jpg`,
        },
      ],
      location: null,
    },
  ],
  form: {
    waiting:
      'Thank you for your interest in this experience, the limited spots are already booked. If you want to be added to the WAITLIST ONLY please submit the following form.',
    note:
      'As this event is limited in capacity, we kindly request you notify us by Monday, November 14th at <a href="mailto:phocuswright@localadventures.travel">phocuswright@localadventures.travel</a> if you are unable to attend.',
  },
}

export default db
